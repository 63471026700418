<template>
	<div style="width: 100%;height: auto;margin-top: 50%;text-align: center">
		{{message}}
	</div>
</template>
<script>

	export default {
		data(){
			return{
				message:"",
				response:"",
				CertifyId:'',
			}
		},

		created() {

		},
		mounted: function () {
			var that = this;
			var url = new URL(window.location.href);
			var response = JSON.parse(url.searchParams.get('response'));
			this.CertifyId = response.extInfo.certifyId;
			that.getFaceResult();
			
		},
		methods:{
			getFaceResult(){
				var that = this;
				this.devAjax('DescribeFaceVerify',{"CertifyId":this.CertifyId},function (ret) {
					console.log(ret)
					if (ret.isSuccess==1){
						if (ret.data.code==0){
							setTimeout(that.getFaceResult,2000)
						}else {
							that.message = ret.data.message
						}
					}else {
						this.$message({
							showClose: true,
							message: ret.data,
							type: 'error'
						});
					}
					
				})
			}
		}
	}
</script>
